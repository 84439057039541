import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PostListing from '../components/PostListing';
import { useSiteMetadata } from '../utils/useSiteMetadata';

// @ts-expect-error Property 'data' does not exist on type
const CategoryTemplate: React.FC = ({ data, pageContext }) => {
  const siteMetadata = useSiteMetadata();

  return (
    <Layout>
      <main>
        <Helmet title={` "${pageContext.category}" - ${siteMetadata.title}`} />
        <h1>Category: {pageContext.category}</h1>
        <PostListing postEdges={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  );
};

export default CategoryTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryPage($category: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { categories: { in: [$category] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            categories
          }
        }
      }
    }
  }
`;
