import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import { IndexQueryQuery } from '../graphqlTypes';

const resetLinkStyle = { border: 'none' };

const PostListing: React.FC<Props> = ({ postEdges }) => {
  const postList = postEdges.map((postEdge) => ({
    path: `/blog${postEdge.node.fields?.slug}`,
    tags: postEdge.node.frontmatter?.tags,
    categories: postEdge.node.frontmatter?.categories,
    cover: postEdge.node.frontmatter?.cover,
    title: postEdge.node.frontmatter?.title,
    date: postEdge.node.fields?.date,
    excerpt: postEdge.node.excerpt,
    timeToRead: postEdge.node.timeToRead,
  }));
  return (
    <article className="card-list">
      {postList.map((post) => (
        <Link to={post.path} key={post.title} style={resetLinkStyle}>
          <section
            className="card"
            css={css`
              height: 300px;
              width: 300px;
              transition: all 0.2s ease-in-out;

              &:hover {
                transform: scale(1.1);
              }
            `}
          >
            <h2>{post.title}</h2>
            <div className="secondary-text">
              <span>{post.categories?.join(' / ')}</span> &mdash;{' '}
              {post.timeToRead} Min Read{' '}
            </div>
            <p>{post.excerpt}</p>
          </section>
        </Link>
      ))}
    </article>
  );
};

export default PostListing;

interface Props {
  postEdges: IndexQueryQuery['allMarkdownRemark']['edges'];
}
